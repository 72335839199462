import MenuIcon from '@mui/icons-material/Menu';
import StorageIcon from '@mui/icons-material/Storage';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import ApiClass from 'Api/ApiClient';
import MathIcon from 'assets/svg/CalculatorIcon.svg';
import DiscountIcon from 'assets/svg/DiscountIcon.svg';
import HomeIcon from 'assets/svg/HomeIcon.svg';
import lookupIcon from 'assets/svg/LookupIcon.svg';
import PromotionsIcon from 'assets/svg/PromotionsIcon.svg';
import ReportIcon from 'assets/svg/ReportIcon.svg';
import ApplicationsIcon from 'assets/svg/TermsConditions.svg';
import AuthContext from 'context/AuthContext';
import DiscardApplicationDialog from 'pages/CreateApplication/DiscardApplicationDialog/DiscardApplicationDialog';
import ExitApplicationDialog from 'pages/CreateApplication/ExitApplicationDialog/ExitApplicationDialog';
import InvitationSuccess from 'pages/CreateApplication/InvitationManualDialog/InvitationSuccess';
import OfferSlider from 'pages/Offers/OfferSlider';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import SkipLink from 'components/SkipLink';

import manageDealerIcon from '../../assets/manageDealerIcon.svg';
import classes from '../../styles/globalStyle.module.scss';

const openedMixin = (theme) => ({
  [theme.breakpoints.up('md')]: {
    width: '20%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '10%',
  },

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: `calc(${theme.spacing(12)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  [theme.breakpoints.up('md')]: {
    width: '20%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '10%',
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('pathname location', location.pathname);
  const ApiClient = new ApiClass();
  let {
    userType,
    accessToken,
    setUserType,
    setAccessToken,
    idToken,
    setIdToken,
    setShowPage,
    setApproveClicked,
    setRejectClicked,
    accessPolicy,
    setAccessPolicy,
    setCreateAppLookup,
    createAppFlag,
    setOpenExitModal,
    invitationSuccess,
    setInvitationSuccess,
    viewApprovalQueue,
  } = useContext(AuthContext);
  const [didMount, setDidMount] = useState(false);
  const [sideMenuList, setSideMenuList] = useState(['Home']);
  const [loader, setLoader] = useState(false);
  const [clickedMenu, setClickedMenu] = useState({});
  const [showOffer, setShowOffer] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = new useQuery();
  const menuMapping = {
    'MANAGE-DEALERS': 'Manage dealers',
    CALCULATOR: 'Payment calculator',
    LOOKUP: 'Lookup',
    'DISTRIBUTOR DATA': 'Distributor Data',
    REPORTS: 'Reports',
    PROMOTIONS: 'Promotions',
    OFFERS: 'Offers',
  };

  useEffect(async () => {
    dealerUserCheck();
    bankerUserCheck();

    setDidMount(true);
    let resp = {};
    const getAccessPolicy = async () => {
      try {
        setLoader(true);
        resp = await ApiClient.post(
          UrlHelpers.accessPolicy,
          {},
          {
            headers: {
              appid: 'auto',
              idtoken: idToken,
              accessType: 'UI',
              accesstoken: accessToken,
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_X_API_KEY,
            },
          }
        );
        resp = JSON.parse(resp?.uiAccessDetails);
        console.log('resp', resp);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }

      return resp;
    };
    let accessResponse = accessPolicy;
    if (!Object.entries(accessPolicy)?.length && query.get('autoloan') !== 'invitation-form') {
      accessResponse = await getAccessPolicy();

      setAccessPolicy(accessResponse);
    }
    const final =
      accessResponse?.allowedAccess?.map((x) => menuMapping[x?.screenId])?.filter((x) => x) || [];
    setSideMenuList(['Home', 'Applications', ...final]);
  }, []);

  const dealerUserCheck = () => {
    if (query.get('userType')) {
      userType = query.get('userType');
      setUserType(userType);
      sessionStorage.setItem('userType', userType);
    }
    if (query.get('idToken') && query.get('accessToken')) {
      accessToken = query.get('accessToken');
      idToken = query.get('idToken');
      setAccessToken(accessToken);
      sessionStorage.setItem('accessToken', accessToken);
      // to be removed
      setIdToken(idToken);
      if (userType === 'dealer') {
        const accessTokenParam = searchParams.get('accessToken');
        const idTokenParam = searchParams.get('idToken');
        if (accessTokenParam && idTokenParam) {
          searchParams.delete('accessToken');
          searchParams.delete('idToken');

          setSearchParams(searchParams);
        }
      }
    }
  };

  const bankerUserCheck = () => {
    const hash = window?.location?.hash;

    if (hash && hash.indexOf('error_description') === -1) {
      accessToken = hash.split('&')[0]?.split('=')[1];
      idToken = hash.split('&')[1]?.split('=')[1];
      setAccessToken(accessToken);
      sessionStorage.setItem('accessToken', accessToken);
      setIdToken(idToken);
      setUserType('banker');
    //   navigate('/home');
    } else if (hash && hash.indexOf('error_description') > -1) {
      navigate('/saml-error');
    } else if (!accessToken) {
      if (query.get('autoSignout') === 'yes') {
        window.location.href = `${process.env.REACT_APP_IDM_URL}/?autoSignout=yes`;
      } else {
        navigate('/login');
      }
    } else if (userType === 'new') {
      navigate('/invi-flow');
    }
  };

  useEffect(() => {
    const navigateMapping = {
      '/home': 'Home',
      '/applications': 'Applications',
      '/home/create-app': 'Home',
      '/new-user-request': 'Home',
      '/manage-dealers': 'Manage dealers',
      '/register-user': 'Manage dealers',
      '/paymentCalculator': 'Payment calculator',
      '/lookup': 'Lookup',
      '/promotions': 'Promotions',
      '/distributed-data': 'Distributor Data',
      '/offers': 'Offers',
      '/reports': 'Reports',
    };
    let activeIndex = sideMenuList.findIndex((x) => x === navigateMapping?.[location.pathname]);
    setSelectedIndex(activeIndex);
  }, [location.pathname]);
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleNavigate = (text) => {
    switch (text) {
      case 'Home': {
        navigate('/home');
        break;
      }
      case 'Applications':
        navigate('/applications', {
          state: { viewUserRequest: viewApprovalQueue },
        });
        break;
      case 'Manage dealers':
        navigate('/manage-dealers');
        break;
      case 'Payment calculator':
        navigate('/paymentCalculator');
        break;
      case 'Lookup':
        navigate('/lookup');
        break;
      case 'Promotions':
        navigate('/promotions');
        break;
      case 'Reports':
        navigate('/reports');
        break;
      case 'Distributor Data':
        navigate('/distributed-data');
        break;
      case 'Offers':
        setShowOffer(true);
        break;
      default:
        break;
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = async (_event, text) => {
    setShowPage('');
    setApproveClicked(false);
    setRejectClicked(false);
    setCreateAppLookup('');
    console.log('createAppFlag - 0', createAppFlag);
    console.log('test - 0', text);
    if (text && createAppFlag) {
      console.log('test', text);
      console.log('createAppFlag', createAppFlag);
      setOpenExitModal(true);
      setClickedMenu(text);
    } else {
      setOpenExitModal(false);
      setClickedMenu({});
    }
    if (!createAppFlag) {
      handleNavigate(text);
    }
  };

  const handleIcons = (index) => {
    switch (index) {
      case 'Home':
        return <img src={HomeIcon} />;
      case 'Manage dealers':
        return <img src={manageDealerIcon} />;
      case 'Payment calculator':
        return <img src={MathIcon} />;
      case 'Lookup':
        return <img src={lookupIcon} />;
      case 'Distributor Data':
        return <StorageIcon />;
      case 'Reports':
        return <img src={ReportIcon} />;
      case 'Offers':
        return <img src={DiscountIcon} />;
      case 'Promotions':
        return <img src={PromotionsIcon} />;
      case 'Applications':
        return <img src={ApplicationsIcon} />;
      default:
        break;
    }
  };

  return (
    <>
      <SkipLink />
      {showOffer && <OfferSlider openModal={showOffer} setOpenModal={setShowOffer} />}
      <Loader open={loader} />
      <ExitApplicationDialog handleNavigate={handleNavigate} clickedMenu={clickedMenu} />
      <InvitationSuccess
        invitationSuccess={invitationSuccess}
        setInvitationSuccess={setInvitationSuccess}
      />
      <DiscardApplicationDialog />
      {didMount && (
        <Box container sx={{ display: 'flex', width: '100%' }}>
          <CssBaseline />

          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: '#E37617',
                color: '#fff',
              },
            }}
            sx={{ whiteSpace: 'break-spaces' }}
          >
            <DrawerHeader sx={{ display: 'block', padding: 0 }}>
              <List>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    sx={{
                      px: 2.5,
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : '',
                        justifyContent: 'center',
                      }}
                    >
                      <MenuIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    {open && <ListItemText primary="Oriental" sx={{ opacity: open ? 1 : 0 }} />}
                  </ListItemButton>
                </ListItem>
              </List>
            </DrawerHeader>

            <Divider />
            <List>
              {sideMenuList.map((text, index) => (
                <ListItem key={text} disablePadding sx={{ display: 'block', margin: '15px 0' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    className={!open ? classes.listItemBtnOpen : classes.listItemBtnClose}
                    // disabled={text === 'Reports'}
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, text)}
                    id={`${text}-menu`}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: '#fff',
                      }}
                    >
                      {handleIcons(text)}
                    </ListItemIcon>

                    <ListItemText primary={text} primaryTypographyProps={{ fontSize: '0.85rem' }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>

          <Box component="main" flexGrow="1" sx={{ width: { lg: '90%', md: '80%' } }}>
            <Header open={open} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.contentContainer}
              id="main-content"
            >
              <Paper elevation={0} className={classes.paperBg}>
                <Outlet />
              </Paper>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
